/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


body{
  /* background-color: #b2aba9; */
  background-image: url(bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  font-family: 'Poppins', sans-serif;

  }

  

  .container{
  background-color: #f2f2f2;
  justify-content: center;
  width: 50%;
  margin-left: 26%;
  margin-top: 5%;
  text-align: center;
  }

  input[type=text], select {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing:border-box;
  }

  input[type=password], select {
  width: 80%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing:border-box;
  }

  button{

    background-color: #9f68e0;
    color: white;
    border: 0;
    border-radius: 10px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;

  }

  button:hover {
  background-color: #45a049;
  }

  .header{
  width: 95%;
  background-color: rgb(243, 240, 243);
  margin-left: 25%;
  width: 50%;
  padding: 20px 30px;
  margin-top: 5%;
  }

  
